.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  width: 55%;
  pointer-events: none;
}

.App-body {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 4vmin);
  flex: 1;
  padding: 30px;
}

.App-description {
  font-family: 'Roboto Mono', monospace;
  color: #1f1f1f;
}

.App-footer {
  background-color: #1f1f1f;
  position: absolute;
  margin: 0px;
}

.App-footer-links {
  margin: 0px;
  display: flex;
  flex-direction: row;
  list-style: none;
  background-color: #1f1f1f;
  align-items: center;
  justify-content: center;
}

.App-footer-link {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 50px;
}

.App-link {
  text-decoration: none;
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-size: 18px;
}

@media all and (max-width: 700px) {
  .App-body {
    font-size: 16px;
  }

  .App-link {
    font-size: 12px;
  }

  .App-footer-link {
    padding: 20px;
  }
}
